.footerdiv{
    background-color: var(--footerBG);
    padding: 50px 0 20px 0;
}
.footerin{
    max-width: 522px;
    padding: 15px;
}
.copyright{
    margin-top: 50px;
}
.copyright p{
    color: #909090;
    text-align: center;
    font-family: var(--font);
    font-size: 16px;
    font-style: normal;
    font-weight: 200;
    line-height: 150%; /* 24px */
}
.footer-logo{
    width: 93px;
    margin: 0 0 20px 0;
}
.footer-para{
    color: #2B2B2B;
    text-align: center;
    font-family: var(--font);
    font-size: 16px;
    font-style: normal;
    font-weight: 200;
    line-height: 150%; /* 24px */
    padding: 0 10px;
    margin: 0 0 80px 0;
}
.footer-link{
    color: #6D6D6D;
    font-family: var(--font);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
}