.web-header{
    padding: 10px 32px;
    margin: 23px auto 60px;
    max-width: 1160px;
    background-color: var(--bgGray);
    border-radius: 10px;
}
.web-logo{
    width: 104px;
}
.head-menu{
    margin: 0 25px;
    color: #2B2B2B;
    font-family: var(--font);
    font-size: 16px;
    font-weight: 600;
}