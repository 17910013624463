@import url('../fonts/font.css');

:root {
    --tmc: #6A3FE4;
    --tmc_bg: #B7A1F5;
    --tmc_bbg: #F4F0FF;

    --ft_bg: #242833;
    
    --h_text: #2B2B2B;
    --g_text: #A0A0A0;
    --w_text: #F5F5F5;

    --hrc: #E0E0E0;

    --disablec: #dddddd;
    --pcolor: #4D4DFF;

    /* font */
    --font: 'Pitagon Sans Text';
    --normal: normal
    --fw4: 400;
    --fw6: 600;
    --fw7: 700;
    --fw8: 800;
    --fw9: 900;
    --bold: "bold";

    /* header text */
    --side_t: 16px;
    --pagetitle: 24px;
    --form_field: 17px;
    --input_t: 15px;
    --side_tm: 13px;
    --h_t: 15px;
    --md_f: 18px;

    /* font ends */

    --bg_white: #ffffff;
    /* static colors */
    --white: #ffffff;
    --black: #000000;
    --green: #00B272;
    --yellow: #FFC149;
    --blue: #3F63E4;

    --t_red: #E43F3F;
    --bg_red: #EFA2A2;

    --t_yellow: #FFC149;
    --bg_yellow: #FDEAC4;

    --t_green: #00B272;
    --bg_green: #BEFFCC;

    --t_purple: #B93FE4;
    --bg_purple: #EEBDFF;
    --bg_light_purple: #ACBEFF;

    --t_blue: #4382BB;
    --t_dark_blue: #3F63E4;
    --t_light_blue: #3FC6E4;
    --bg_blue: #92C5F4;
    --bg_light_blue: #B3EAF6;

    --side_icon: #f5f5f5;
    --side_text: #f5f5f5;
    /* static colors ends */

    /* shadow and border */
    --br_rs: 4px;
    --br_r: 10px;
    --shadow_1: drop-shadow(0px 10px 32px rgba(24, 39, 75, 0.10)) drop-shadow(0px 6px 14px rgba(24, 39, 75, 0.12));
    --shadow_2: drop-shadow(0px 14px 64px rgba(24, 39, 75, 0.12)) drop-shadow(0px 8px 22px rgba(24, 39, 75, 0.12));
    --shadow_3: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    --shadow_4: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    /* shadow and border ends */

    /* z-index */
    --l9: 999999999;
    --l8: 99999999;
    --l7: 9999999;
    --l6: 999999;
    --l5: 99999;
    --l4: 9999;
    --l3: 999;
    --l2: 99;
    --l1: 9;
    --l0: 0;
    --l_1: -9;
    /* z-index ends */

    /* margin */
    --lm_3: 10px;
    --tm_3: 10px;
    /* margin ends */

    /* padding */
    --allp_2: 15px;
    /* padding ends */

    /* transition */
    --fast_t: 0.3s;
    /* transition ends */

    /* screen area */
    --vw: 100vw;
    --vh: 100vh;  
    /* screen area ends */
    
  }