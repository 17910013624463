/* animation */
@keyframes shakeAnimation {
    0%, 100% {
        transform: translateX(0);
    }
    10%, 30%, 50%, 65%, 90% {
        transform: translateY(-10px);
    }
    20%, 40%, 55%, 70% {
        transform: translateX(10px);
    }
    25%, 45%, 60%, 80% {
        transform: translateX(-10px);
    }
}
/* amination ends */

.tleft{
    text-align: left;
}
.tright{
    text-align: right;
}
.title-text{
    color: var(--textTitle);
    font-family: var(--font);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.headtext-div{
    margin-bottom: 10px;
}
.main-text{
    position: relative;
    color: var(--mainText);
    font-family: var(--font);
    font-size: 39px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 58.5px */
    max-width: 658px;
}
.elet{
    position: absolute;
    top: -25px;
    right: -10px;
    animation: shakeAnimation 15s infinite ease-in-out;
}
.head-para{
    color: var(--mainText);
    font-family: var(--font);
    font-size: 16px;
    font-style: normal;
    font-weight: 200;
    line-height: 150%; /* 24px */
    max-width: 538px;
}