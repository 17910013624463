/* animation */
@keyframes rotateAnimation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
/* amination ends */

.banner80{
    width: 874px;
    margin: 25px auto;
}
.banner100{
    width: 100%;
    margin: 25px auto;
}
.store-icons{
    width: 80px;
    margin-left: 20px;
}
.avail{
    color: var(--subText);
    font-family: var(--font);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.divider{
    width: 100%;
    border: none;
    border-top: 1px solid #ECECEC;
    margin: 50px 0;
}
.hi-para{
    max-width: 900px;
    margin: 32px auto;
    text-align: center;
    color: var(--subText);
    text-align: center;
    font-family: var(--font);
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 37.5px */
}
.text-student{
    height: 40px;
    position: relative;
    top: 10px;
}

/* bg section */
.bg-div{
    position: relative;
    padding: 10px 0 100px;
}
.bg-curv{
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
}
/* bg section ends */

/* download section */
.downloadsec{
    background-color: var(--pcolor);
    margin: 100px auto;
    padding: 50px 25px;
    border-radius: 25px;
    position: relative;
    overflow: hidden;
}
.cur-out{
    position: absolute;
    top: 30px;
    left: 0px;
    width: 100%;   
    text-align: center;
}
.cur-out-img{
    width: 70%;
    animation: rotateAnimation 10s infinite linear;
    z-index: 49;
}
.downloadsec-texthead{
    position: relative;
    z-index: 99;
}
/* download section ends */