.btn-outline{
    border-radius: 10px;
    border: 1px solid var(--pcolor);
    background: #FFF;
    padding: 21px 41px;
    color: var(--pcolor);
    font-family: var(--font);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    transition: 0.3s;
    cursor: pointer;
}
.btn-outline-tr{
    background-color: transparent;
}
.btn-outline:hover{
    background: var(--pcolor);
    color: #FFFFFF;
}
.btn-font{
    color: var(--pcolor);
    font-family: var(--font);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

/* filled */
.btn-Filled{
    margin: 25px auto 0;
    border-radius: 10px;
    border: 1px solid var(--pcolor);
    background: var(--pcolor);
    padding: 21px 41px;
    color: #ffffff;
    font-family: var(--font);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    transition: 0.3s;
    cursor: pointer;
}
.left{
    margin: 25px auto 0 0;
}
.right{
    margin: 25px 0 0 auto;
}
.btn-FilledW{
    border: 1px solid #ffffff;
    background: #ffffff;
    color: var(--pcolor);
}
.btn-Filled:hover{
    /* background: #ffffff;
    color: var(--pcolor); */
    opacity: 0.8;
}

.button-re{
    cursor: pointer;
    border-radius: 39px;
    background: #FFF2E4;
    padding: 10px 15px;
    margin: 30px auto;
    display: flex;
    align-items: center;
}
.button-re p{
    color: #F69223;
    font-family: var(--font);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.arrow-re{
    background-color: #f6942386;
    border-radius: 50%;
    padding: 5px;
    width: 22px;
    height: 22px;
    margin-left: 5px;
}
.arrow-re-icon{
    width: 100%;
    color: #F69223;
}